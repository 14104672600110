var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"DBzzAz2CQZ42nMp7Tlb1n"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesDistDir__"] = "build";

// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs"

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  debug: false,
  dsn: "https://fe638318269b052aa20a78f87abb70ba@o4506400358989824.ingest.sentry.io/4506422509371392",
  environment: process.env.ENVIRONMENT || "dev",
  tracesSampleRate: getTracesSampleRate(),
})

export function getTracesSampleRate() {
  return ["tst", "acc", "prod"].includes(process.env.ENVIRONMENT) ? 0.1 : 0
}
